import {Button} from "@material-ui/core";
import {Field, FieldArray, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import moment from "moment";
import {PropsWithChildren} from "react";
//import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";
import "./AddShiftsComponentForOpenShift.scss";
import {shiftsFormValidation} from "./AddShiftsFormValidationForOpenShift";
import {useParams} from "react-router-dom";

export interface AddHcpToShiftComponentProps {
    confirm: () => void;
    cancel: () => void;
    basicDetails: any;
    selectedHcps: any;
    setSelectedHcps: any;
    afterConfirm: any;
    hcpList: any;
}

const AddShiftsComponentForOpenShift = (props: PropsWithChildren<AddHcpToShiftComponentProps>) => {

    const cancel = props?.cancel;
    const confirm = props?.confirm;
    const basicDetails = props?.basicDetails;
    const selectedHcps = props?.selectedHcps;
    const setSelectedHcps = props?.setSelectedHcps;
    const afterConfirm = props?.afterConfirm;
    const hcpList = props?.hcpList;
    //  const [hcpPayDate, setHcpPayDate] = useState<null | string>(null);
    const params = useParams<{ id: string }>();
    const {id} = params;

    const createShiftsInitialState = (shiftArr: any[]) => {
        let initialStateFiltered = shiftArr.map((shift) => {
            const {start_time, end_time} = CommonService.getUtcTimeinHours(
                basicDetails?.shift_timings?.start_time,
                basicDetails?.shift_timings?.end_time
            );
            const start_date = CommonService.getUtcDate(basicDetails?.shift_timings?.start_time);
            const end_date = CommonService.getUtcDate(basicDetails?.shift_timings?.end_time);
            const facility_name = basicDetails?.facility?.facility_name;
            const hcp_first_name = shift?.hcp_data?.first_name;
            const hcp_last_name = shift?.hcp_data?.last_name;

            return {
                hcp_user_id: shift,
                start_date: start_date && moment(start_date).format("YYYY-MM-DD"),
                end_date: end_date && moment(end_date).format("YYYY-MM-DD"),
                start_time: start_time,
                end_time: end_time,
                differential_amount: 0,
                facility_name: facility_name,
                hcp_first_name: hcp_first_name,
                hcp_last_name: hcp_last_name,
            };
        });

        return {
            requirements: initialStateFiltered,
        };
    };


    const formatAllShiftTimingsToSeconds = (shifts: any[]) => {
        console.log(shifts);
        const formattedShifts = shifts.map((shift) => {
            return {
                hcp_user_id: shift?.hcp_user_id,
                differential_amount: Number(shift?.differential_amount),
                start_time: CommonService.convertHoursToMinutes(shift.start_time),
                start_date: shift?.start_date,
                end_time: CommonService.convertHoursToMinutes(shift?.end_time),
                end_date: shift?.end_date,
            };
        });
        return formattedShifts;
    };


    const onAdd = (shifts: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        const formattedShifts = formatAllShiftTimingsToSeconds(shifts?.requirements);
        const formattedShiftsWithHcpPayDate: any = formattedShifts.map((shift: any) => ({
            ...shift,
        }));
        console.log(formattedShiftsWithHcpPayDate);
        const payload = {
            requirements: formattedShiftsWithHcpPayDate
        };
        // formattedShiftsWithHcpPayDate.map((item: any) => {
        //     return (
        CommonService._api.post(ENV.API_URL + "requirement/" + id + "/applications", payload).then((resp) => {
            CommonService.showToast(resp?.msg || "Success", "success");
            if (confirm) {
                setSubmitting(true);
                confirm();
                afterConfirm();
                setSelectedHcps([]);
            }
        })
            .catch((err) => {
                console.log(err);
                CommonService.showToast(err.error || "Error", "error");
                if (confirm) {
                    setSubmitting(true);
                    confirm();
                    afterConfirm();
                    setSelectedHcps([]);
                }
            });
        // });
    };

    // if (isLoading) {
    //     return (
    //         <Paper>
    //             <div className="pdd-100" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
    //                 <LoaderComponent position="block"/>
    //             </div>
    //         </Paper>
    //     );
    // }

    return (
        (
            <div className="create-shift mrg-10 pdd-10">
                <div className="shift-heading-container">
                    <h2 className="shift-heading">Shift Details</h2>
                </div>
                <Formik
                    initialValues={createShiftsInitialState(selectedHcps)}
                    validateOnChange={true}
                    validationSchema={shiftsFormValidation}
                    onSubmit={onAdd}
                >
                    {({isSubmitting, isValid, resetForm, values}) => (
                        <Form>
                            {/*<div className="hcp-pay-date-container mrg-top-20">*/}
                            {/*    <Field*/}
                            {/*        onChange={(e: any) => {*/}
                            {/*            setHcpPayDate(e.target.value);*/}
                            {/*        }}*/}
                            {/*        value={hcpPayDate}*/}
                            {/*        variant="outlined"*/}
                            {/*        name={`hcp_pay_date`}*/}
                            {/*        type="date"*/}
                            {/*        InputLabelProps={{shrink: true}}*/}
                            {/*        component={TextField}*/}
                            {/*        label="HCP Pay Date (MM/DD/YYYY)"*/}
                            {/*        fullWidth*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <FieldArray
                                name="fields"
                                render={({...props}) => (
                                    <div>
                                        {values?.requirements &&
                                            values.requirements.map((shift, index) => {
                                                // console.log(values);
                                                // console.log(shift);
                                                const hcpDetails = hcpList?.filter((item: any) => item?.user_id === shift?.hcp_user_id);
                                                console.log(hcpDetails);
                                                return (
                                                    <div className="shift-container" key={index}>
                                                        <h3>
                                                            {hcpDetails[0]?.first_name} {hcpDetails[0]?.last_name} (
                                                            {shift?.facility_name ? shift?.facility_name : "N/A"})
                                                        </h3>
                                                        <div className="input-container mrg-top-10">
                                                            <Field
                                                                variant="outlined"
                                                                name={`requirements.${index}.start_date`}
                                                                type="date"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                label="Start Date* (MM/DD/YYYY)"
                                                                fullWidth
                                                                disabled={true}
                                                            />
                                                            <Field
                                                                name={`requirements.${index}.start_time`}
                                                                variant="outlined"
                                                                type="time"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                label="Start Time*"
                                                                fullWidth
                                                                disabled={true}
                                                            />
                                                            {basicDetails?.type === "facility" &&
                                                                <Field
                                                                    name={`requirements.${index}.differential_amount`}
                                                                    variant="outlined"
                                                                    InputLabelProps={{shrink: true}}
                                                                    type={"text"}
                                                                    component={TextField}
                                                                    label="Differential Amount"
                                                                    fullWidth
                                                                />}
                                                        </div>
                                                        <div
                                                            className={`input-container${basicDetails?.type === "facility" ? "-minor" : ""} mrg-top-10`}>
                                                            <Field
                                                                name={`requirements.${index}.end_date`}
                                                                variant="outlined"
                                                                type="date"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                label="End Date* (MM/DD/YYYY)"
                                                                fullWidth
                                                                disabled={true}
                                                            />
                                                            <Field
                                                                name={`requirements.${index}.end_time`}
                                                                variant="outlined"
                                                                type="time"
                                                                InputLabelProps={{shrink: true}}
                                                                component={TextField}
                                                                label="End Time*"
                                                                fullWidth
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            />

                            <div className="create-shift-actions mrg-top-10">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color={"primary"}
                                    id="create-shift-cancel-btn"
                                    onClick={() => cancel()}
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    id="btn_hcp_edit_submit"
                                    size="large"
                                    variant={"contained"}
                                    color={"primary"}
                                    className={isSubmitting ? "normal has-loading-spinner" : "normal"}
                                >
                                    {!isSubmitting ? "CREATE" : "CREATING"}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    );
};

export default AddShiftsComponentForOpenShift;
