import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { AddRounded, SearchRounded } from "@material-ui/icons";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from "../../../classes/ts-data-list-wrapper.class";
import { ApiService, Communications } from "../../../helpers";
import { useLocalStorage } from "../../../components/useLocalStorage";
import { ENV } from "../../../constants";
import LoaderComponent from "../../../components/LoaderComponent";
import ClearIcon from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";
import NoDataCardComponent from "../../../components/NoDataCardComponent";
import "./EmployeeListScreen.scss";
import AccessControlComponent from "../../../components/AccessControl";
import { ADMIN } from "../../../helpers/common-service";
import {fetchRegionsRequest} from "../../../store/actions/meta.action";
import {useDispatch} from "react-redux";

const CssTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#10c4d3",
            },
        },
    },
})(TextField);

const EmployeeListScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("hcpPageSizeIndex", 10);
    const dispatch=useDispatch()
    const init = useCallback(() => {
        let url = "user/list";
        // let payload: any = {};
        // payload.is_approved = 0;
        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
               // extraPayload: payload,
                webMatColumns: [" Name", "Contact Number", "Email","Role", "Status", "Actions"],
                mobileMatColumns: [" Name", "Contact Number", "Email","Role", "Status", "Actions"],
            },
            ENV.API_URL + url, setList, ApiService, "post");

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({ table: tableWrapperObj });
        // eslint-disable-next-line
    }, []);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }

        // let payload: any = {};
        // payload.is_approved = 0;
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        Communications.pageTitleSubject.next("Employee List");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(()=>{
        dispatch(fetchRegionsRequest())
    },[dispatch])


    return (
        <>
            <div className={"ac-list screen crud-layout pdd-30"}>
                {list && list.table?._isDataLoading && (
                    <div className="table-loading-indicator">
                        <LoaderComponent />
                    </div>
                )}
                <div className="custom-border pdd-10  pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!list?.table.filter.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded />
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        if (list && list.table) {
                                                            list.table.filter.search = "";
                                                            list.table.reload();
                                                            // list?.table.pageEvent(0)
                                                        }
                                                    }}
                                                    id="clear_hcp_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_hcp"
                                                onChange={(event) => {
                                                    if (list && list.table) {
                                                        list.table.filter.search = event.target.value;
                                                        list.table.reload();
                                                        // list?.table.pageEvent(0)
                                                    }
                                                }}
                                                value={list?.table.filter.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Employee"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action d-flex">
                            <div className="mrg-left-20">
                               <AccessControlComponent role={[ADMIN]}>
                               <Tooltip title="Add New Employee">
                                    <Button variant={"contained"} color={"primary"} component={Link} to={`/employee/add`}>
                                        <AddRounded />
                                        &nbsp;&nbsp;Add New&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                               </AccessControlComponent>
                            </div>
                        </div>
                    </div>
                    {list && list.table && (
                        <>
                            <TableContainer component={Paper} className={"table-responsive"}>
                                <Table stickyHeader className="mat-table table employee-list-table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                                <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"} key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={"mat-tbody"}>
                                        {!list.table._isDataLoading && list.table?.data.length === 0 && <NoDataCardComponent tableCellCount={list.table.matColumns.length} />}

                                        {list?.table.data.map((row: any, rowIndex: any) => {
                                            return (
                                                <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex} className={"mat-tr"}>
                                                    <TableCell className="mat-td mat-td-employee-name">
                                                        {row["first_name"]}&nbsp;{row["last_name"]}
                                                    </TableCell>
                                                    <TableCell className="mat-td mat-td-contact-number">{row["contact_number"]}</TableCell>
                                                    <TableCell className="mat-td mat-td-email">{row["email"]}</TableCell>
                                                    <TableCell className="mat-td mat-td-role">{row["role"]}</TableCell>
                                                    <TableCell className={row["is_active"]===true?"mat-td mat-td-status Active":"mat-td mat-td-status Inactive"}>{row["is_active"]===true?"Active":"Inactive"}</TableCell>
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        <Tooltip title={`${row["first_name"]} ${row["last_name"]} view details`}>
                                                            <Link to={"/employee/view/" + row["_id"]} className="info-link" id={"link_hospital_details" + rowIndex}>
                                                                {"View Details"}
                                                            </Link>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                                component="div"
                                count={list?.table.pagination.totalItems}
                                rowsPerPage={list?.table.pagination.pageSize}
                                page={list?.table.pagination.pageIndex}
                                onPageChange={(event, page) => list.table.pageEvent(page)}
                                onRowsPerPageChange={(event) => {
                                    setPageSizeIndex(event.target.value);
                                    list.table?.pageEvent(0, +event.target.value);
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default EmployeeListScreen;