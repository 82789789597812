import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { AddRounded, SearchRounded } from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
import "./NcAssignedHcpsListComponent.scss";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import { ENV } from "../../../../constants";
import ClearIcon from "@material-ui/icons/Clear";
import { CommonService, Communications } from "../../../../helpers";
import { Link, useParams } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import DialogComponent from "../../../../components/DialogComponent";
import RemoveHCPComponent from "./removeHcp/RemoveHCPComponent";
import AddHcpToNC from "./addHcpToNC/AddHcpToNC";
import LoaderComponent from "../../../../components/LoaderComponent";
import AccessControlComponent from "../../../../components/AccessControl";
import { ADMIN, OPERATIONALMANAGER } from "../../../../helpers/common-service";
import { CssTextField } from "../../../../constants/data/styles";


const NcAssignedHcpsListComponent = (props: any) => {
  const param = useParams<any>();
  const { id } = param;
  const [selectedHcps, setSelectedHcps] = useState<any>([]);
  const [isAllselected, setAllSelected] = useState<boolean>(false);
  // eslint-disable-next-line
  const [selectedCount, setSelectedCount] = useState<any>(-1);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const list = props?.list;
  const setPageSizeIndex = props?.setPageSizeIndex;
  const getList = props?.getList;
  const basicDetails = props?.basicDetails;

  const cancelAdd = useCallback(() => {
    setIsAddOpen(false);
  }, []);

  const confirmAdd = useCallback(() => {
    setIsAddOpen(false);
  }, []);

  const openAdd = useCallback(() => {
    setIsAddOpen(true);
  }, []);

  const handleSelectAll = (event: any) => {
    if (event.target.checked === true) {
      let temp: any[] = [];
      list?.table?.data?.forEach((item: any) => {
        let index = selectedHcps?.indexOf(item?._id);
        if (index === -1) {
            temp.push(item._id);
        }
      });
      setSelectedHcps([...selectedHcps, ...temp]);
      setSelectedCount(1);
    } else {
      list?.table?.data?.forEach((item: any) => {
        let index = selectedHcps?.indexOf(item?._id);
        selectedHcps.splice(index, 1);
      });
      setSelectedHcps([...selectedHcps]);
      setSelectedCount(selectedHcps?.length === 0 ? -1 : selectedHcps?.length);
    }
    setAllSelected(event.target.checked);
  };

  const handleSelectShifts = useCallback((event: any, _id: any) => {
      if (event.target.checked === true) {
        setSelectedHcps([...selectedHcps, _id]);
        setSelectedCount(1);
      } else {
        let tempSelectedHcps = selectedHcps?.filter((item: any) => item !== _id);
        setSelectedHcps([...tempSelectedHcps]);
        if (tempSelectedHcps?.length === 0) {
          setSelectedCount(-1);
        }
      }
    },
    [selectedHcps]
  );

  const isSelected = useCallback((_id: any) => {
      if (selectedHcps?.indexOf(_id) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    [selectedHcps]
  );

  useEffect(() => {
    let count = 0;
    list?.table?.data?.forEach((item: any) => {
      if (selectedHcps?.indexOf(item?._id) !== -1) {
        count++;
      }
    });
    if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [list?.table?.data, selectedHcps]);

  const handleDeleteHcps = useCallback(() => {
    let payload = {
      hcp_ids: [...selectedHcps],
    };
    CommonService._api.delete(ENV.API_URL + "user/" + id + "/unlinkNC", payload).then((resp) => {
        CommonService.showToast(resp?.msg || "Success", "success");
        setIsConfirmDelete(false);
        setIsDeleteOpen(false);
        setSelectedCount(-1);
        setSelectedHcps([])
        getList();
      })
      .catch((err) => {
        CommonService.showToast(err?.msg || "Error", "error");
        setIsConfirmDelete(false);
      });
  }, [id, selectedHcps, getList]);

  const openDeleteHcps = useCallback(() => {
    setIsDeleteOpen(true);
  }, []);

  const cancelDeleteHcps = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  const confirmDeleteHcps = useCallback(() => {
    setIsConfirmDelete(true);
    handleDeleteHcps();
  }, [handleDeleteHcps]);

  useEffect(() => {
    Communications.pageTitleSubject.next("Employee List");
    Communications.pageBackButtonSubject.next("/employee/list");
  }, []);

  if(list?.table?.isDataLoading ){
      return <LoaderComponent position="block" />
  }

  return (
    <>
      <DialogComponent open={isAddOpen} cancel={cancelAdd}>
        <AddHcpToNC cancel={cancelAdd} confirm={confirmAdd} hcp_type={""} getList={getList} />
      </DialogComponent>
      <DialogComponent open={isDeleteOpen} cancel={cancelDeleteHcps} maxWidth={"xs"}>
        <RemoveHCPComponent cancel={cancelDeleteHcps} confirm={confirmDeleteHcps} confirmationText="Do you want to remove the selected HCPs from the list ?" notext={"NO"} yestext={"YES"} isConfirm={isConfirmDelete} />
      </DialogComponent>

      <div className={"hcp-assigned-nc-list screen crud-layout pdd-0"}>
        <div className="custom-border pdd-10  pdd-top-20 pdd-bottom-0">
          <div className="header">
            <div className="mrg-left-5 filter">
              <div>
                <div className="d-flex">
                  <div className="d-flex position-relative">
                    {!list?.table.filter.search ? (
                      <div className={"search_icon"}>
                        <SearchRounded />
                      </div>
                    ) : (
                      <div className={"search_icon"}>
                        <ClearIcon
                          onClick={(event) => {
                            if (list && list.table) {
                              list.table.filter.search = "";
                              list.table.reload();
                              // list?.table.pageEvent(0)
                            }
                          }}
                          id="clear_hcp_search"
                        />
                      </div>
                    )}
                    <div>
                      <CssTextField
                        defaultValue={""}
                        className="search-cursor searchField"
                        id="input_search_hcp"
                        onChange={(event) => {
                          if (list && list.table) {
                            list.table.filter.search = event.target.value;
                            list.table.reload();
                            // list?.table.pageEvent(0)
                          }
                        }}
                        value={list?.table.filter.search}
                        variant={"outlined"}
                        size={"small"}
                        type={"text"}
                        placeholder={"Search HCP"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AccessControlComponent role={[ADMIN,OPERATIONALMANAGER]}>
            <div className="action d-flex">
              <div className="mrg-left-20">
                <Tooltip title={basicDetails?.is_active ? "Add Staff" : "Activate Employee to Add Staff"}>
                  <span>
                  <Button disabled={!basicDetails?.is_active} onClick={openAdd} variant={"contained"} color={"primary"}>
                    <AddRounded />
                    &nbsp;Add Staff
                  </Button>
                  </span>
                </Tooltip>
              </div>
              <div className="mrg-left-20">
                <Tooltip title="Remove Hcp">
                 <span>
                 <Button className={selectedHcps?.length === 0 ? 'btn-disabled' : ''} variant={"outlined"} color={"primary"} onClick={openDeleteHcps} disabled={selectedHcps?.length === 0}>
                    &nbsp;&nbsp;Remove HCP &nbsp;&nbsp;
                  </Button>
                 </span>
                </Tooltip>
              </div>
            </div>
            </AccessControlComponent>
          </div>

          {list && list.table && (
            <>
              <TableContainer component={Paper} className={"table-responsive"}>
                <Table stickyHeader className="mat-table table nc-assigned-hcps-list-table">
                  <TableHead className={"mat-thead"}>
                    <TableRow className={"mat-tr"}>
                      <TableCell padding="checkbox" className="mat-th">
                        <Checkbox onChange={(event) => handleSelectAll(event)} checked={isAllselected} id={"select-all-cb"} />
                      </TableCell>
                      {list?.table.matColumns.map((column: any, columnIndex: any) => (
                        <TableCell className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"} key={"header-col-" + columnIndex}>
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={"mat-tbody"}>
                    {!list.table._isDataLoading && list.table?.data.length === 0 && <NoDataCardComponent tableCellCount={list.table.matColumns.length} />}
                    {list?.table.data.map((row: any, rowIndex: any) => {
                      const isItemSelected = isSelected(row["_id"]);
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex} className={"mat-tr"}>
                          <TableCell className="mat-td mat-td-checkbox">
                            <Checkbox id={"cb_" + rowIndex} checked={isItemSelected} onChange={(event) => handleSelectShifts(event, row["_id"])} />
                          </TableCell>
                          <TableCell className="mat-td mat-td-hcp-name">
                            {row["first_name"]}&nbsp;{row["last_name"]}
                          </TableCell>
                          <TableCell className="mat-td mat-td-contact-number">{row["contact_number"]}</TableCell>
                          <TableCell className="mat-td mat-td-role">{row["hcp_type"]}</TableCell>
                          <TableCell className={row["is_active"] === true ? "mat-td mat-td-monthly-status Active" : "mat-td mat-td-monthly-status Inactive"}>{row["is_active"] === true ? "Active" : "Inactive"}</TableCell>
                          <TableCell className="mat-td mat-td-actions mat-td-sticky">
                            <Tooltip title={`${row["first_name"]} ${row["last_name"]} view details`}>
                              <Link to={{ pathname: '/hcp/user/view/' + row?._id, state: { prevPath: "/employee/view/"+id } }}
                               className="info-link" id={"link_hospital_details" + rowIndex}>
                                {"View Details"}
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                component="div"
                count={list?.table.pagination.totalItems}
                rowsPerPage={list?.table.pagination.pageSize}
                page={list?.table.pagination.pageIndex}
                onPageChange={(event, page) => list.table.pageEvent(page)}
                onRowsPerPageChange={(event) => {
                  setPageSizeIndex(event.target.value);
                  list.table?.pageEvent(0, +event.target.value);
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NcAssignedHcpsListComponent;
