import {Button, Radio, RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import Lottie from "react-lottie";
//import { useSelector } from "react-redux";
import animationData from "../../../../../assets/animations/NoData.json";
import LoaderComponent from "../../../../../components/LoaderComponent";
import {ENV} from "../../../../../constants";
import {CssTextField, useStyles} from "../../../../../constants/data/styles";
import {CommonService} from "../../../../../helpers";
import "./AddHcpComponent.scss";
import DialogComponent from "../../../../../components/DialogComponent";
import {Field, Form, Formik} from "formik";
//import {shiftsFormValidation} from "../../addShiftsComponentForOpenShift/AddShiftsFormValidationForOpenShift";
import {TextField} from "formik-material-ui";
import * as Yup from "yup";

export const shiftFormValidation = Yup.object().shape({
    differential_amount: Yup.number().typeError("must be a number").max(9999, "max limit 9999.").required("Required"),
});

export interface AddHcpToShiftComponentProps {
    cancel: () => void;
    confirm: () => void;
    hcp_type: any;
    selectedHcp: any;
    setSelectedHcp: any;
    shiftsCount: number;
    selectedShifts: any[];
    selectedHcps?: any;
    requirementType?: any;
}


const AddHcpComponent = (props: PropsWithChildren<AddHcpToShiftComponentProps>) => {
    const afterCancel = props?.cancel;
    const requirementType = props?.requirementType;
    // const hcp_type = props?.hcp_type;
    const selectedHcp = props?.selectedHcp;
    const setSelectedHcp = props?.setSelectedHcp;
    const shiftsCount = props?.shiftsCount;
    const selectedShifts = props?.selectedShifts;
    const classes = useStyles();
    const selectedHcps = props?.selectedHcps;
    const [hcpList, sethcpList] = React.useState<any>(null);
    const [searchHcp, setSearchHcp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    //const { user } = useSelector((state: StateParams) => state.auth);
    const [isShiftAddDetailsOpen, setIsShiftAddDetailsOpen] = useState<boolean>(false);

    const createShiftInitialState = (shiftArr: any[]) => {
        let initialStateFiltered = shiftArr.map((shift) => {
            return shift

        });
        return {
            requirement_ids: initialStateFiltered,
        };
    };


    const cancelShiftAddDetails = useCallback(() => {
        setIsShiftAddDetailsOpen(false);
    }, []);


    const defaultOptions = {
        animationData,
    };

    const init = useCallback(() => {
        setIsLoading(true);
        let payload: any = {
            is_approved: true,
            // hcp_type: hcp_type,
        };
        if (selectedHcps?.length > 0) {
            //const hcpTitles = selectedHcps.map((item: any) => item.title);
            const combinedTitleIndex = selectedHcps?.indexOf("CNA/CHHA");
            const cnaIndex = selectedHcps?.indexOf("CNA");
            const chhaIndex = selectedHcps?.indexOf("CHHA");
            if (combinedTitleIndex !== -1) {
                const combinedTitle = selectedHcps[combinedTitleIndex];
                payload.hcp_types = [
                    ...selectedHcps?.filter((title: any) => title !== "CNA/CHHA"), // Remove "CNA/CCHA"
                    "CNA", // Add "CNA"
                    "CHHA", // Add "CCHA"
                    combinedTitle, // Add "CNA/CCHA" separately
                ];
            } else if (cnaIndex !== -1) {
                payload.hcp_types = [
                    ...selectedHcps, // Keep existing types
                    "CNA/CHHA", // Add "CNA/CCHA"
                ];
            } else if (chhaIndex !== -1) {
                payload.hcp_types = [
                    ...selectedHcps, // Keep existing types
                    "CNA/CHHA", // Add "CNA/CCHA"
                ];
            } else {
                payload.hcp_types = selectedHcps;
            }
        }

        if (searchHcp !== "") {
            payload.search = searchHcp
        }

        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                sethcpList(resp?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [searchHcp, selectedHcps]);

    const handleChange = (event: any) => {
        console.log(event?.target?.value);
        setSelectedHcp(event.target.value);
    };

    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    useEffect(() => {
        init();
    }, [init]);


    const onAdd = useCallback((shifts: any) => {
        setIsSubmitting(true);
        console.log(selectedHcp);

        if (requirementType?.value === "facility") {
            const payload = {
                ...shifts,
            };
            CommonService._api
                .post(ENV.API_URL + `hcp/${selectedHcp}/applications`, payload)
                .then((resp: any) => {
                    setIsSubmitting(false);
                    afterCancel();
                    CommonService.showToast(resp?.msg || "Success", "success");
                    setIsShiftAddDetailsOpen(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsSubmitting(false);
                    CommonService.showToast(err?.error || "Error", "error");
                    setIsShiftAddDetailsOpen(false);
                });
        } else if (requirementType?.value !== "facility") {
            const payload = {
                requirement_ids: shifts,
            };
            CommonService._api
                .post(ENV.API_URL + `hcp/${selectedHcp}/applications`, payload)
                .then((resp: any) => {
                    setIsSubmitting(false);
                    afterCancel();
                    CommonService.showToast(resp?.msg || "Success", "success");
                    setIsShiftAddDetailsOpen(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsSubmitting(false);
                    CommonService.showToast(err?.error || "Error", "error");
                    setIsShiftAddDetailsOpen(false);
                });
        }

    }, [selectedHcp, requirementType, afterCancel]);

    const handleSubmit = useCallback(() => {
        if (requirementType?.value === "facility") {
            setIsShiftAddDetailsOpen(true);
        } else {
            onAdd(selectedShifts)
        }
    }, [requirementType, onAdd, selectedShifts]);
    return (
        <div className="add-hcp-requirment">
            <DialogComponent maxWidth={'md'} open={isShiftAddDetailsOpen} cancel={cancelShiftAddDetails}>
                <div>
                    <div className="create-shift mrg-10 pdd-10">
                        <div className="shift-heading-container">
                            <h2 className="shift-heading">Shift Details</h2>
                        </div>
                        <Formik
                            initialValues={createShiftInitialState(selectedShifts)}
                            validateOnChange={true}
                            validationSchema={shiftFormValidation}
                            onSubmit={onAdd}
                        >
                            {({isSubmitting, isValid, resetForm, values}) => (
                                <Form>
                                    {/*<div className="hcp-pay-date-container mrg-top-20">*/}
                                    {/*    <Field*/}
                                    {/*        onChange={(e: any) => {*/}
                                    {/*            setHcpPayDate(e.target.value);*/}
                                    {/*        }}*/}
                                    {/*        value={hcpPayDate}*/}
                                    {/*        variant="outlined"*/}
                                    {/*        name={`hcp_pay_date`}*/}
                                    {/*        type="date"*/}
                                    {/*        InputLabelProps={{shrink: true}}*/}
                                    {/*        component={TextField}*/}
                                    {/*        label="HCP Pay Date (MM/DD/YYYY)"*/}
                                    {/*        fullWidth*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <Field
                                        name="fields"
                                        render={({...props}) => (
                                            <div>
                                                <div className="shift-container">
                                                    <div className="input-container mrg-top-10">
                                                        <Field
                                                            name={`differential_amount`}
                                                            variant="outlined"
                                                            InputLabelProps={{shrink: true}}
                                                            type={"text"}
                                                            component={TextField}
                                                            label="Differential Amount*"
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />

                                    <div className="create-shift-actions mrg-top-10">
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            color={"primary"}
                                            id="create-shift-cancel-btn"
                                            onClick={() => setIsShiftAddDetailsOpen(false)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            id="btn_hcp_edit_submit"
                                            size="large"
                                            variant={"contained"}
                                            color={"primary"}
                                            className={isSubmitting ? "normal has-loading-spinner" : "normal"}
                                        >
                                            {!isSubmitting ? "ADD" : "ADDING"}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </DialogComponent>
            <div className={classes.paper}>
                <h2 className={classes.title}>{shiftsCount > 1 ? "Add an Staff to Shifts" : "Add Staff to Shift"}</h2>
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Staff
                </FormLabel>
                <div id="alert-dialog-title">
                    <div className="mrg-top-20">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    <CssTextField
                                        defaultValue={""}
                                        onChange={(event) => {
                                            setSearchHcp(event?.target?.value);
                                        }}
                                        className="searchField"
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Hcp"}
                                        value={searchHcp}
                                    />
                                    {searchHcp === "" ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrg-top-20">
                        {isLoading && <LoaderComponent position="block"/>}
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" value={selectedHcp}
                                    name="controlled-radio-buttons-group" onChange={handleChange}>
                            {hcpList?.map((item: any) => {
                                return (
                                    <div>
                                        <FormControlLabel value={item?.user_id} control={<Radio/>}
                                                          label={item?.first_name + " " + item?.last_name}/>
                                    </div>
                                );
                            })}
                        </RadioGroup>
                    </div>
                </div>

                {!isLoading && hcpList?.length === 0 && (
                    <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
                        <Lottie width={400} height={400} speed={1} options={defaultOptions}/>
                    </div>
                )}

                {hcpList && hcpList.length > 0 ? (
                    <div className={classes.assignNcActions}>
                        <Button type={"submit"} size="large" variant={"outlined"} className={"normal"} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button className={isSubmitting ? "has-loading-spinner mrg-left-30" : "mrg-left-30"}
                                type={"submit"} color={"primary"} size="large" disabled={!selectedHcp || isSubmitting}
                                variant={"contained"} onClick={handleSubmit}>
                            {
                                isSubmitting ? "Saving" : "Save"
                            }
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AddHcpComponent;
