import {Button, TablePagination, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass
} from "../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import {ENV} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import ShiftFilter from "../../filters/ShiftFilter";
import CancelShiftRequirement from "../cancelShiftRequirement/CancelShiftRequirementComponent";
import "./openShiftsListScreen.scss";
import Checkbox from "@material-ui/core/Checkbox";
import AddHcpToShifts from "../addHcpToShiftsDialog/AddHcpToShiftsDialog";
import ShowShiftRateOnMobileComponent from "../showShiftRateOnMobile/ShowShiftRateOnMobileComponent";
import {CssTextField} from "../../../../constants/data/styles";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";

export const defaultSelectedStatusType = 'open';

const OpenShiftsListScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [regions, setRegions] = useState<any>([]);
    const {regions} = useSelector((state: StateParams) => state.meta);
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any[]>("selectedRegion", []);
    const [selectedStatusTypes, setSelectedStatusTypes] = useLocalStorage<any[]>("selectedStatusTypesInOpenShifts", [defaultSelectedStatusType]);
    const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [null, null]);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftReqPageSizeIndex", 10);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isAllselected, setAllSelected] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<any>(-1);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isAddHcpToShiftsOpen, setIsAddHcpToShiftsOpen] = useState<boolean>(false);
    const [isHCPRateOnMobileOpen, setIsHCPRateOnMobileOpen] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isShowShiftRate, setIsShowShiftRate] = useLocalStorage<any>("isShowShiftRate", "");
    const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);
    const [shiftSearch, setShiftSearch] = useState<string>("");
    const [requestedBy, setRequestedBy] = useLocalStorage<any>("requested-by-open-shift", "");
    const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("shift-requirement-type-open", "facility");


    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            const selectedRegions = selectedRegion;
            payload.regions = selectedRegions;
        }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, [selectedRegion]);

    const checkForDisablingHcpButton = () => {
        const isCancelledSelected = selectedStatusTypes.includes('cancelled');
        const isUnfilledSelected = selectedStatusTypes.includes('unfilled');
        if (selectedCount === -1 || selectedShifts.length === 0 || isCancelledSelected || isUnfilledSelected || selectedHcps.length > 1 || selectedHcps.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const checkForDisablingShowHcpRateButton = () => {
        if (selectedShifts.length === 0 || selectedCount === -1) {
            return true;
        } else {
            return false;
        }
    };

    const checkForDisablingCancelButton = () => {
        const isCancelledSelected = selectedStatusTypes.includes('cancelled');
        const isUnfilledSelected = selectedStatusTypes.includes('unfilled');
        if (selectedCount === -1 || selectedShifts.length === 0 || isCancelledSelected || isUnfilledSelected) {
            return true;
        } else {
            return false;
        }
    };

    const checkForDisablingSelectAllCheckbox = () => {
        const isCancelledSelected = selectedStatusTypes.includes('cancelled');
        const isUnfilledSelected = selectedStatusTypes.includes('unfilled');
        const result = isCancelledSelected || isUnfilledSelected || selectedStatusTypes === null;
        return result;
    };

    useEffect(() => getFacilityData(), [selectedRegion, getFacilityData]);

    const init = useCallback(() => {
        let url = "requirement/list";
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            //const hcpTitles = selectedHcps.map((item: any) => item.title);
            const combinedTitleIndex = selectedHcps.indexOf("CNA/CHHA");
            if (combinedTitleIndex !== -1) {
                const combinedTitle = selectedHcps[combinedTitleIndex];
                payload.hcp_types = [
                    ...selectedHcps.filter((title: any) => title !== "CNA/CHHA"), // Remove "CNA/CCHA"
                    "CNA", // Add "CNA"
                    "CHHA", // Add "CCHA"
                    combinedTitle, // Add "CNA/CCHA" separately
                ];
            } else {
                payload.hcp_types = selectedHcps;
            }
        }
        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }


        if (isShowShiftRate?.value !== '') {
            payload.is_shift_rate = isShowShiftRate.value;
        }

        if (requestedBy?.value !== '') {
            payload.requested_by = requestedBy?.value;
        }

        if (shiftRequirementType?.value !== "") {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            const isUnfilledSelected = selectedStatusTypes.includes('unfilled');
            if (!isUnfilledSelected) {
                let today = moment(new Date()).format("YYYY-MM-DD");
                payload.new_shifts = today;
            }
        }
        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: ["Title", "Facility Name", "Shift Date", "Staff Type", "No. of Staff", "Shift Hours", "Shift Type", "HCPs Filled", "Status", "Show Shift Rate", "Actions"],
                mobileMatColumns: ["Title", "Facility Name", "Shift Date", "Staff Type", "No. of Staff", "Shift Hours", "Shift Type", "HCPs Filled", "Status", "Show Shift Rate", "Actions"],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, []);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        // if (selectedHcps.length > 0) {
        //     payload.hcp_types = selectedHcps;
        // }
        if (selectedHcps.length > 0) {
            //const hcpTitles = selectedHcps.map((item: any) => item.title);
            const combinedTitleIndex = selectedHcps.indexOf("CNA/CHHA");
            if (combinedTitleIndex !== -1) {
                const combinedTitle = selectedHcps[combinedTitleIndex];
                payload.hcp_types = [
                    ...selectedHcps.filter((title: any) => title !== "CNA/CHHA"), // Remove "CNA/CCHA"
                    "CNA", // Add "CNA"
                    "CHHA", // Add "CCHA"
                    combinedTitle, // Add "CNA/CCHA" separately
                ];
            } else {
                payload.hcp_types = selectedHcps;
            }
        }

        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }

        if (isShowShiftRate?.value !== '') {
            payload.is_shift_rate = isShowShiftRate.value;
        }

        if (requestedBy?.value !== '') {
            payload.requested_by = requestedBy?.value;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            const isUnfilledSelected = selectedStatusTypes.includes('unfilled');
            if (!isUnfilledSelected) {
                let today = moment(new Date()).format("YYYY-MM-DD");
                payload.new_shifts = today;
            }
        }
        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, [dateRange, selectedRegion, selectedApprovedHcps, selectedTimeTypes, selectedHcps, selectedFacilities, selectedStatusTypes, setAllSelected, isShowShiftRate, requestedBy, shiftRequirementType]);

    const handleDownload = useCallback(() => {
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            //const hcpTitles = selectedHcps.map((item: any) => item.title);
            const combinedTitleIndex = selectedHcps.indexOf("CNA/CHHA");
            if (combinedTitleIndex !== -1) {
                const combinedTitle = selectedHcps[combinedTitleIndex];
                payload.hcp_types = [
                    ...selectedHcps.filter((title: any) => title !== "CNA/CHHA"), // Remove "CNA/CCHA"
                    "CNA", // Add "CNA"
                    "CHHA", // Add "CCHA"
                    combinedTitle, // Add "CNA/CCHA" separately
                ];
            } else {
                payload.hcp_types = selectedHcps;
            }
        }
        if (selectedStatusTypes.length > 0) {
            payload.status = selectedStatusTypes;
        }
        if (isShowShiftRate?.value !== '') {
            payload.is_shift_rate = isShowShiftRate.value;
        }

        if (requestedBy?.value !== '') {
            payload.requested_by = requestedBy?.value;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }
        if (shiftSearch !== "") {
            payload.search = shiftSearch;
        }
        setIsDownloading(true);
        ApiService.post(ENV.API_URL + "requirement/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.error || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.error || "Error", "error");
            });
    }, [dateRange, selectedFacilities, selectedApprovedHcps, selectedHcps, selectedTimeTypes, selectedStatusTypes, selectedRegion, isShowShiftRate, shiftSearch, requestedBy, shiftRequirementType]);

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item._id);
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            setSelectedCount(1);
        } else {
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
            });
            setSelectedShifts([...selectedShifts]);
            setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setAllSelected(event.target.checked);
    };

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
                setSelectedCount(1);
            } else {
                let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
                setSelectedShifts([...tempSelectedShifts]);
                if (tempSelectedShifts?.length === 0) {
                    setSelectedCount(-1);
                }
            }
        },
        [selectedShifts]
    );

    const clearFilterValues = () => {
        setSelectedTimeTypes([]);
        setSelectedFacilities([]);
        setSelectedApprovedHcps([]);
        setSelectedHcps([]);
        setDateRange([null, null]);
        setSelectedRegion([]);
        setIsShowShiftRate("");
        setRequestedBy("");
        setRequirementType("");
        setSelectedStatusTypes([defaultSelectedStatusType]);

    };

    const resetFilters = () => {
        clearFilterValues();
    };

    const resetSelectedCount = () => {
        setSelectedCount(-1);
        setSelectedShifts([]);
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Open Shifts");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        setAllSelected(false);
    }, [list?.table?.data]);

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);


    const openAddHcpToShifts = useCallback(() => {
        setIsAddHcpToShiftsOpen(true);
    }, []);

    const openShowHCPRateOnMobile = useCallback(() => {
        setIsHCPRateOnMobileOpen(true);
    }, []);

    const cancelOpenShowHCPRateOnMobile = useCallback(() => {
        setIsHCPRateOnMobileOpen(false);
        setSelectedShifts([]);
        setSelectedCount(-1);
    }, []);

    const cancelOpenAddHcpToShifts = useCallback(() => {
        setIsAddHcpToShiftsOpen(false);
        setSelectedShifts([]);
        setSelectedCount(-1);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedShifts([]);
        setSelectedCount(-1);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setSelectedCount(-1);
        setSelectedShifts([]);
        list?.table?.reload();
        setRejectShiftOpen(false);
    }, [setSelectedCount, setSelectedShifts, list?.table]);

    const confirmAddHcpToShifts = useCallback(() => {
        setSelectedShifts([]);
        setSelectedCount(-1);
        list?.table?.reload();
        setIsAddHcpToShiftsOpen(false);
    }, [list?.table]);

    const confirmOpenShowHCPRateOnMobile = useCallback(() => {
        setSelectedShifts([]);
        setSelectedCount(-1);
        list?.table?.reload();
        setIsHCPRateOnMobileOpen(false);
    }, [list?.table]);


    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts]
    );

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [list?.table?.data, selectedShifts]);

    return (
        <>
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <CancelShiftRequirement cancel={cancelRejectShift} confirm={confirmRejectShift}
                                        selectedShifts={selectedShifts}/>
            </DialogComponent>

            <DialogComponent open={isAddHcpToShiftsOpen} cancel={cancelOpenAddHcpToShifts} maxWidth="md">
                <AddHcpToShifts requirementType={shiftRequirementType} cancel={cancelOpenAddHcpToShifts}
                                confirm={confirmAddHcpToShifts}
                                selectedHcp={selectedHcps} selectedHcps={selectedHcps} selectedShifts={selectedShifts}/>
            </DialogComponent>

            <DialogComponent open={isHCPRateOnMobileOpen} cancel={cancelOpenShowHCPRateOnMobile} maxWidth="sm">
                <ShowShiftRateOnMobileComponent
                    cancel={cancelOpenShowHCPRateOnMobile}
                    confirm={confirmOpenShowHCPRateOnMobile}
                    selectedShifts={selectedShifts}
                    type={'multiple'}
                />
            </DialogComponent>

            <div className={"open-shifts-list screen crud-layout pdd-30"}>
                {list && list.table?._isDataLoading && (
                    <div className="table-loading-indicator">
                        <LoaderComponent/>
                    </div>
                )}
                <ShiftFilter
                    requestedBy={requestedBy}
                    shiftRequirementType={shiftRequirementType}
                    setRequestedBy={setRequestedBy}
                    setRequirementType={setRequirementType}
                    resetSelectedCount={resetSelectedCount}
                    isFacilityListLoading={isFacilityListLoading}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    regions={regions}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    selectedHcps={selectedHcps}
                    setSelectedHcps={setSelectedHcps}
                    selectedTimeTypes={selectedTimeTypes}
                    setSelectedTimeTypes={setSelectedTimeTypes}
                    selectedFacilities={selectedFacilities}
                    setSelectedFacilities={setSelectedFacilities}
                    selectedStatusTypes={selectedStatusTypes}
                    setSelectedStatusTypes={setSelectedStatusTypes}
                    noStatus={false}
                    isOpenShift={true}
                    facilityList={facilityList}
                    hcpTypes={hcpTypes}
                    isShowShiftRate={isShowShiftRate}
                    setIsShowShiftRate={setIsShowShiftRate}
                    selectedApprovedHcps={selectedApprovedHcps}
                    setSelectedApprovedHcps={setSelectedApprovedHcps}
                    resetFilters={resetFilters}
                />
                <div className="custom-border pdd-10  pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!list?.table.filter.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        if (list && list.table) {
                                                            list.table.filter.search = "";
                                                            setShiftSearch("");
                                                            list.table.reload();
                                                            // list?.table.pageEvent(0)
                                                        }
                                                    }}
                                                    id="clear_requirment_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_requirment"
                                                onChange={(event) => {
                                                    if (list && list.table) {
                                                        list.table.filter.search = event.target.value;
                                                        setShiftSearch(event.target.value);
                                                        list.table.reload();
                                                        // list?.table.pageEvent(0)
                                                    }
                                                }}
                                                value={list?.table.filter.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Shift"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shift-req-actions d-flex">
                            {shiftRequirementType?.value === "facility" &&
                                <div className="mrg-left-10">
                                    <Tooltip
                                        title={checkForDisablingHcpButton() ? "Show Shift Rate on Mobile" : "Show Shift Rate on Mobile"}>
									<span>
										<Button
                                            className={checkForDisablingShowHcpRateButton() ? "btn-disabled" : ""}
                                            variant={"outlined"}
                                            color={"primary"}
                                            disabled={checkForDisablingShowHcpRateButton()}
                                            onClick={openShowHCPRateOnMobile}
                                        >
											Show Shift Rate on Mobile
										</Button>
									</span>
                                    </Tooltip>
                                </div>}
                            <div className="mrg-left-10">
                                <Tooltip
                                    title={checkForDisablingHcpButton() ? "Please select Staff Type and Shift to Add Staff" : "Add Staff to Shifts"}>
									<span>
										<Button className={checkForDisablingHcpButton() ? "btn-disabled" : ""}
                                                variant={"outlined"} color={"primary"}
                                                disabled={checkForDisablingHcpButton()} onClick={openAddHcpToShifts}>
											Add Staff
										</Button>
									</span>
                                </Tooltip>
                            </div>

                            <div className="mrg-left-10">
                                <Tooltip title={"Cancel Shift Requirement"}>
									<span>
										<Button
                                            variant={"outlined"}
                                            color={"primary"}
                                            disabled={checkForDisablingCancelButton()}
                                            className={checkForDisablingCancelButton() ? "btn-disabled" : ""}
                                            onClick={openRejectShift}
                                        >
											Cancel Shifts&nbsp;&nbsp;
										</Button>
									</span>
                                </Tooltip>
                            </div>
                            {/*<div className="mrg-left-10">*/}
                            {/*    <Tooltip title={"Add New Shift Requirement"}>*/}
                            {/*        <Button component={Link} to={"/open-shift/BulkUpload"} variant={"contained"}*/}
                            {/*                color={"primary"}>*/}
                            {/*            &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;*/}
                            {/*        </Button>*/}
                            {/*    </Tooltip>*/}
                            {/*</div>*/}
                            <div className="mrg-left-10">
                                <Tooltip title={"Add New Shift Requirement"}>
                                    <Button component={Link} to={"/shift/add"} variant={"contained"} color={"primary"}>
                                        <AddRounded/>
                                        &nbsp;&nbsp;Add New&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="actions pdd-right-5">
                                <Tooltip title="Download Shifts List">
                                    <Button variant={"contained"} color="primary" onClick={handleDownload}
                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                            disabled={isDownloading}>
                                        &nbsp;Download
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {list && list.table && (
                        <>
                            <TableContainer component={Paper} className={"table-responsive"}>
                                <Table stickyHeader className="mat-table table open-shifts-list-table">
                                    <TableHead className={"mat-thead"}>
                                        <TableRow className={"mat-tr"}>
                                            <TableCell padding="checkbox" className="mat-th">
                                                <Checkbox
                                                    disabled={checkForDisablingSelectAllCheckbox()}
                                                    onChange={(event) => handleSelectAll(event)}
                                                    checked={isAllselected}
                                                    id={"select-all-cb"}
                                                />
                                            </TableCell>
                                            {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                                <TableCell
                                                    className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                    key={"header-col-" + columnIndex}>
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={"mat-tbody"}>
                                        {!list.table._isDataLoading && list.table?.data.length === 0 &&
                                            <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>}
                                        {list.table?.data.length > 0 &&
                                            list?.table.data.map((row: any, rowIndex: any) => {
                                                const {
                                                    start_time,
                                                    end_time
                                                } = CommonService.getUtcTimeInAMPM(row["shift_timings"]?.start_time, row["shift_timings"]?.end_time);
                                                const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                                const isItemSelected = isSelected(row["_id"]);
                                                const isShowHcpRate = row["is_shift_rate"];
                                                return (
                                                    <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                              className={"mat-tr"}>
                                                        <TableCell className="mat-td mat-td-checkbox">
                                                            <Checkbox id={"cb_" + rowIndex}
                                                                      disabled={row["status"] === "cancelled"}
                                                                      checked={isItemSelected}
                                                                      onChange={(event) => handleSelectShifts(event, row["_id"])}/>
                                                        </TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-title">{shift_date + "_" + row["shift_type"] + "_" + row["facility"]?.name}</TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-shift-date">{shift_date}</TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-hcp-type">{row["hcp_type"]}</TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-hcp-count">{row["hcp_count"]}</TableCell>
                                                        <TableCell className="mat-td mat-td-shift-timings">
                                                            {start_time}&nbsp;-&nbsp;{end_time}
                                                        </TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-shift-type">{row["shift_type"]}</TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-shift-type">{row["approved_hcps"] >= 0 ? row["approved_hcps"] + "/" + row["hcp_count"] : "N/A"}</TableCell>
                                                        <TableCell
                                                            className={`${row["status"]} mat-td mat-td-status`}>{row["status"]}</TableCell>
                                                        <TableCell className="mat-td mat-td-is-facility-paid ">
                                                            <span
                                                                className={`${isShowHcpRate === true ? "success" : "danger"}`}>{"is_shift_rate" in row ? (isShowHcpRate === true ? "Yes" : "No") : "NA"}</span>
                                                        </TableCell>
                                                        <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                            <Tooltip title={`${row["title"]} view details`}>
                                                                <Link to={"/shiftsRequirements/view/" + row["_id"]}
                                                                      className="info-link"
                                                                      id={"link_hospital_details" + rowIndex}>
                                                                    {"View Details"}
                                                                </Link>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                                component="div"
                                count={list?.table.pagination.totalItems}
                                rowsPerPage={list?.table.pagination.pageSize}
                                page={list?.table.pagination.pageIndex}
                                onPageChange={(event, page) => list.table.pageEvent(page)}
                                onRowsPerPageChange={(event) => {
                                    setPageSizeIndex(event.target.value);
                                    list.table?.pageEvent(0, +event.target.value);
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default OpenShiftsListScreen;
