import React, {useCallback, useEffect, useState} from "react";
import {ENV, ImageConfig} from "../../../../constants";
import {CommonService, Communications} from "../../../../helpers";
import {Button, Tab, Tabs, Tooltip} from "@material-ui/core";
import DialogComponent from "../../../../components/DialogComponent";
import "./RequirementsShiftsViewScreen.scss";
import AddHcpToShiftScreen from "./AddHcpToShift/AddHcpToShiftScreen";
import {useParams} from "react-router-dom";
import moment from "moment";
import ApprovedHcpApplicationComponent from "./approved/ApprovedHcpApplicationComponent";
import UnApprovedHcpApplicationComponent from "./unapproved/UnApprovedHcpApplicationComponent";
import {AddRounded} from "@material-ui/icons";
import RejectShiftRequirementComponent from "../cancelShiftRequirement/CancelShiftRequirementComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import PendingHcpApplicationComponent from "./pending/PendingHcpApplicationComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import ShowShiftRateOnMobileComponent from "../showShiftRateOnMobile/ShowShiftRateOnMobileComponent";

const RequirementsShiftsViewScreen = () => {
    const param = useParams<any>();
    const {id} = param;
    const [tabValue, setTabValue] = useLocalStorage<string>("ShiftRequirmentTabValue", "pending");
    const [basicDetails, setBasicDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isHCPRateOnMobileOpen, setIsHCPRateOnMobileOpen] = useState<boolean>(false);


    const handleTabChange = (event: any, value: any) => {
        setTabValue(value);
    };

    const getShiftDetails = useCallback(() => {
        CommonService._api
            .get(ENV.API_URL + "requirement/" + id)
            .then((resp) => {
                setBasicDetails(resp?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [id]);


    const getUpdatedShiftData = useCallback(() => {
        return new Promise((resolve, reject) => {
            CommonService._api
                .get(ENV.API_URL + "requirement/" + id)
                .then((resp) => {
                    setBasicDetails(resp?.data);
                    resolve(null);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                    setIsLoading(false);
                });
        });
    }, [id]);

    useEffect(() => {
        getShiftDetails();
    }, [getShiftDetails]);

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);


    const confirmAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);


    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
    }, []);

    const confirmRejectShift = useCallback(() => {
        getShiftDetails();
        setRejectShiftOpen(false);
    }, [getShiftDetails]);

    const openShowHCPRateOnMobile = useCallback(() => {
        setIsHCPRateOnMobileOpen(true);
    }, []);

    const cancelOpenShowHCPRateOnMobile = useCallback(() => {
        setIsHCPRateOnMobileOpen(false);
    }, []);

    const confirmOpenShowHCPRateOnMobile = useCallback(() => {
        getUpdatedShiftData().then(res => setIsHCPRateOnMobileOpen(false));
    }, [getUpdatedShiftData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Open Shifts");
        Communications.pageBackButtonSubject.next("/shiftrequirementMaster/list");
    }, []);

    const {
        start_time,
        end_time
    } = CommonService.getUtcTimeInAMPM(basicDetails?.shift_timings?.start_time, basicDetails?.shift_timings?.end_time);

    const shift_date = CommonService.getUtcDate(basicDetails?.shift_date);
    const start_date = CommonService.getUtcDate(basicDetails?.shift_timings?.start_time);
    const end_date = CommonService.getUtcDate(basicDetails?.shift_timings?.end_time);


    if (isLoading) {
        return <LoaderComponent/>;
    }

    const address = [
        basicDetails?.patient_details?.address?.apt,
        basicDetails?.patient_details?.address?.street,
        basicDetails?.patient_details?.address?.city,
        basicDetails?.patient_details?.address?.state,
        basicDetails?.patient_details?.address?.zip_code
    ].filter(Boolean).join(', ') || '-';

    return (
        <div className="pending-shifts-view screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftRequirementComponent cancel={cancelRejectShift} confirm={confirmRejectShift}
                                                 selectedShifts={null}/>
            </DialogComponent>
            <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                <AddHcpToShiftScreen cancel={cancelAdd}
                                     confirm={confirmAdd} hcp_type={basicDetails?.hcp_type}
                                     basicDetails={basicDetails}/>
            </DialogComponent>

            <DialogComponent open={isHCPRateOnMobileOpen} cancel={cancelOpenShowHCPRateOnMobile} maxWidth="sm">
                <ShowShiftRateOnMobileComponent
                    cancel={cancelOpenShowHCPRateOnMobile}
                    confirm={confirmOpenShowHCPRateOnMobile}
                    singleShiftId={id}
                    type={'single'}
                    shiftDetails={basicDetails}
                />
            </DialogComponent>
            {!isLoading && (
                <>
                    <div className="header">
                        <div className="filter"></div>
                        <div className="actions">
                            {basicDetails?.status !== "cancelled" ? (
                                <Tooltip title={`Cancel Shift Requirement`}>
                                    <Button variant={"contained"} onClick={openRejectShift} color={"primary"}>
                                        Cancel Shift Requirement
                                    </Button>
                                </Tooltip>
                            ) : (
                                <p className="status-header">
                                    Status:&nbsp;<span className="status">Cancelled</span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="facility-details custom-border">
                        <h2>{basicDetails?.facility?.name}</h2>
                        <p>
                            {basicDetails?.facility?.address?.street},&nbsp;{basicDetails?.facility?.address?.region_name},&nbsp;{basicDetails?.facility?.address?.city},&nbsp;{basicDetails?.facility?.address?.country},&nbsp;
                            {basicDetails?.facility?.address?.zip_code}.
                        </p>
                    </div>
                    {basicDetails?.type === "home" &&
                        <div className="facility-details custom-border mrg-top-10">
                            <div className={'d-flex'}>
                                {basicDetails && basicDetails?.patient_details?.first_name &&
                                    <h3 className={'mrg-right-5'}>{basicDetails?.patient_details?.first_name}</h3>}
                                {basicDetails && basicDetails?.patient_details?.middle_name &&
                                    <h3 className={'mrg-right-5'}>{basicDetails?.patient_details?.middle_name}</h3>}
                                {basicDetails && basicDetails?.patient_details?.last_name &&
                                    <h3>{basicDetails?.patient_details?.last_name}</h3>}
                                {
                                    <h3 className={"mrg-left-10"}>
                                        ({basicDetails && CommonService.calculateAge(basicDetails?.patient_details?.dob)}years, {basicDetails && basicDetails?.patient_details?.gender})
                                    </h3>
                                }
                            </div>
                            <p>
                                {address}
                            </p>
                            <p>
                                MRN no : {basicDetails && basicDetails?.patient_details?.mrn}
                            </p>

                            <div className={'mrg-top-25 d-flex'} style={{
                                color: "#808080"
                            }}>
                                <div>
                                    <span>Phone Number : {basicDetails && basicDetails?.patient_details?.contact_number}</span>
                                </div>

                                <div className={'d-flex'}>
                                    <span>&nbsp;| Email Address : {basicDetails && basicDetails?.patient_details?.email}</span>
                                </div>

                            </div>
                        </div>}


                    <div className="facility-details mrg-top-10 custom-border">
                        {basicDetails?.type === "home" &&
                            <>
                                <div className="d-flex shift-name-requested">

                                    <div className="shift-name-action-container d-flex">
                                        <h2>Shift Details</h2>
                                        {/*<h4 className="shift-detail-edit" onClick={openShowHCPRateOnMobile}>(Edit)</h4>*/}
                                    </div>

                                    <div className="d-flex requested-on-wrapper">
                                        <h3>Created On:</h3>
                                        <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <p>{basicDetails?.title}</p>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Visit Patient By Date</h3>
                                        <p>{shift_date}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Time</h3>
                                        <p>
                                            {/*{start_time}*/}
                                            {/*&nbsp;-&nbsp;*/}
                                            {start_time} - {end_time}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Visit Type</h3>
                                        <p>{basicDetails?.shift_type}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Staff Type</h3>
                                        <p>{basicDetails?.hcp_type}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>No. of Staff Required</h3>
                                        <p>{basicDetails?.hcp_count}</p>
                                    </div>
                                    <div className={'flex-1'}>
                                        <>
                                            <h3>Requested By</h3>
                                            <p>{basicDetails?.requested_by && basicDetails?.requested_by}</p>
                                        </>
                                    </div>
                                    <div className="flex-1">
                                        <>
                                            <h3>Total Number of Visits</h3>
                                            <p>{basicDetails?.home_requirements?.number_of_visits ? (basicDetails?.home_requirements?.number_of_visits) : "NA"}</p>
                                        </>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Show Shift Rate in Mobile ?</h3>
                                        <p>
                                            {basicDetails?.is_shift_rate
                                                ? basicDetails?.is_shift_rate === "true" || basicDetails?.is_shift_rate === true
                                                    ? "Yes"
                                                    : "No"
                                                : "NA"}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Shift Rate</h3>
                                        <p>$ {basicDetails?.shift_rate ? basicDetails?.shift_rate : "NA"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Staff Speciality</h3>
                                        <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ? basicDetails?.hcp_speciality : "NA") : "NA"}</p>

                                    </div>


                                    <div className="flex-1">
                                        <h3>Created By</h3>
                                        <p>{basicDetails?.requirement_owner_details ? (basicDetails?.requirement_owner_details?.first_name + " " + basicDetails?.requirement_owner_details?.last_name) : "NA"}</p>

                                    </div>

                                    <div className="flex-1">
                                        <>
                                            <h3>Type</h3>
                                            <p>{"Per Job/Visit"}</p>
                                        </>
                                    </div>
                                </div>

                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <>
                                            <h3>Language</h3>
                                            <p>{basicDetails?.home_requirements?.languages ? basicDetails?.home_requirements?.languages?.filter((language: any) => language !== null)
                                                .join(', ') : "NA"}</p>
                                        </>
                                    </div>

                                    <div className="flex-1">
                                        <>
                                            <h3>Gender Preference</h3>
                                            <p>{basicDetails?.home_requirements?.gender_pref ? CommonService.capitalizeFirstLetter(basicDetails?.home_requirements?.gender_pref) : "NA"}</p>
                                        </>
                                    </div>
                                    {/*<div className="flex-1">*/}
                                    {/*    <>*/}
                                    {/*        <h3>Visit Patient By</h3>*/}
                                    {/*        <p>{basicDetails?.home_requirements?.visit_patient_by ? CommonService.capitalizeFirstLetter(basicDetails?.home_requirements?.visit_patient_by) : "NA"}</p>*/}
                                    {/*    </>*/}
                                    {/*</div>*/}
                                    <div className="flex-1">
                                        <h3>Visit Patient By Time</h3>
                                        <p>
                                            {/*{start_time}*/}
                                            {/*&nbsp;-&nbsp;*/}
                                            {end_time}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                    </div>
                                </div>


                                {/*<div className="shift-details">*/}
                                {/*    <div>*/}
                                {/*        <h3>Shift Requirement Details</h3>*/}
                                {/*        <p className="summary">{basicDetails?.shift_details}</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                                    {basicDetails?.documents && basicDetails?.documents?.map((file: any, index: number) => {
                                        return (
                                            <>
                                                <div
                                                    className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-10'}>
                                                            <img src={ImageConfig.FileViewIcon} alt=""/>
                                                        </div>
                                                        <div className={"underline-text"} onClick={() => {
                                                            window.open(file);
                                                        }}>
                                                            document {index + 1}
                                                        </div>
                                                    </div>
                                                    {/*<div className={'cursor-pointer'} onClick={() => {*/}
                                                    {/*    removeFile(index);*/}
                                                    {/*}}>*/}
                                                    {/*    <DeleteIcon/>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </>
                        }
                        {basicDetails?.type !== "home" && basicDetails?.type !== "contract" &&
                            <>
                                <div className="d-flex shift-name-requested">

                                    <div className="shift-name-action-container d-flex">
                                        <h2>Shift Details</h2>
                                        <h4 className="shift-detail-edit" onClick={openShowHCPRateOnMobile}>(Edit)</h4>
                                    </div>

                                    <div className="d-flex requested-on-wrapper">
                                        <h3>Created On:</h3>
                                        <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <p>{basicDetails?.title}</p>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Required On</h3>
                                        <p>{shift_date}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Time</h3>
                                        <p>
                                            {start_time} &nbsp;-&nbsp;{end_time}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Type</h3>
                                        <p>{basicDetails?.shift_type}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Staff Type</h3>
                                        <p>{basicDetails?.hcp_type}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>No. of Staff Required</h3>
                                        <p>{basicDetails?.hcp_count}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Warning Zone</h3>
                                        <p>{basicDetails?.warning_type}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Rush Rate</h3>
                                        <p>{basicDetails?.rush_rate ? basicDetails?.rush_rate : "NA"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Weekend Rate</h3>
                                        <p>{basicDetails?.weekend_rate ? basicDetails?.weekend_rate : "NA"}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>NOC Diff</h3>
                                        <p>{basicDetails?.noc_diff ? basicDetails?.noc_diff : "NA"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>PM Diff</h3>
                                        <p>{basicDetails?.pm_diff ? basicDetails?.pm_diff : "NA"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Show Shift Rate in Mobile ?</h3>
                                        <p>
                                            {basicDetails?.is_shift_rate
                                                ? basicDetails?.is_shift_rate === "true" || basicDetails?.is_shift_rate === true
                                                    ? "Yes"
                                                    : "No"
                                                : "NA"}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Rate</h3>
                                        <p>{basicDetails?.shift_rate ? basicDetails?.shift_rate : "NA"}</p>
                                    </div>

                                </div>

                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Hazard Rate</h3>
                                        <p>{basicDetails?.hasOwnProperty("hazard_rate") ? (basicDetails?.hazard_rate ?? "NA") : "NA"}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Staff Speciality</h3>
                                        <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ? basicDetails?.hcp_speciality : "NA") : "NA"}</p>

                                    </div>


                                    <div className="flex-1">
                                        <h3>Created By</h3>
                                        <p>{basicDetails?.requirement_owner_details ? (basicDetails?.requirement_owner_details?.first_name + " " + basicDetails?.requirement_owner_details?.last_name) : "NA"}</p>

                                    </div>
                                    <div className={'flex-1'}>
                                        <>
                                            <h3>Requested By</h3>
                                            <p>{basicDetails?.requested_by && basicDetails?.requested_by}</p>
                                        </>
                                    </div>
                                </div>

                                <div className="shift-details">
                                    <div>
                                        <h3>Shift Requirement Details</h3>
                                        <p className="summary">{basicDetails?.shift_details}</p>
                                    </div>
                                </div>


                                <div className={'d-flex mrg-top-10 mrg-bottom-20'}>
                                    {basicDetails?.documents && basicDetails?.documents?.map((file: any, index: number) => {
                                        return (
                                            <>
                                                <div
                                                    className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-10'}>
                                                            <img src={ImageConfig.FileViewIcon} alt=""/>
                                                        </div>
                                                        <div className={"underline-text"} onClick={() => {
                                                            window.open(file);
                                                        }}>
                                                            document {index + 1}
                                                        </div>
                                                    </div>
                                                    {/*<div className={'cursor-pointer'} onClick={() => {*/}
                                                    {/*    removeFile(index);*/}
                                                    {/*}}>*/}
                                                    {/*    <DeleteIcon/>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </>}
                        {basicDetails?.type === "contract" &&
                            <>
                                <div className="d-flex shift-name-requested">

                                    <div className="shift-name-action-container d-flex">
                                        <h2>Shift Details</h2>
                                        <h4 className="shift-detail-edit" onClick={openShowHCPRateOnMobile}>(Edit)</h4>
                                    </div>

                                    <div className="d-flex requested-on-wrapper">
                                        <h3>Created On:</h3>
                                        <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                <p>{basicDetails?.title}</p>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Required Dates</h3>
                                        <p>{start_date}&nbsp;  -  &nbsp;{end_date}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Duration</h3>
                                        <p>
                                            {basicDetails?.contract_requirements?.shift_duration ? basicDetails?.contract_requirements?.shift_duration + "h" : "NA"}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Staff Type</h3>
                                        <p>{basicDetails?.hcp_type || "-"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Staff Speciality</h3>
                                        <p>{basicDetails?.hcp_speciality || "-"}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Shift Type</h3>
                                        <p>{basicDetails?.shift_type || "NA"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Days Per Week</h3>
                                        <p>{basicDetails?.contract_requirements?.days_per_week || 0}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Job Type</h3>
                                        <p>{basicDetails?.contract_requirements?.contract_type || "-"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Open Positions</h3>
                                        <p>{basicDetails?.hcp_count || 0}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Visit Rate</h3>
                                        <p>$ {basicDetails?.shift_rate ? basicDetails?.shift_rate : "NA"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Number of weeks</h3>
                                        <p> {basicDetails?.contract_requirements?.no_of_weeks || "-"}</p>
                                    </div>
                                    <div className="flex-1">
                                        {/*<h3>Weekly Earnings</h3>*/}
                                        {/*<p>$ {basicDetails?.contract_requirements?.weekly_earning || 0}</p>*/}
                                    </div>

                                    <div className="flex-1">
                                    </div>
                                </div>

                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Rates</h3>
                                        <div>
                                            {
                                                Array.isArray(basicDetails?.contract_requirements?.travel_per_diems) &&
                                                basicDetails.contract_requirements.travel_per_diems.length > 0 ? (
                                                    basicDetails.contract_requirements.travel_per_diems.map((item: any, index: any) => {
                                                        const entries = Object.entries(item);
                                                        const [key, value]: any = entries[0] || ['', 0];
                                                        const [key2, valuetwo]: any = entries[1] || ['', 0];

                                                        return (
                                                            <div key={index} className="mrg-bottom-10">
                                                                <div className="d-flex">
                                                                    <p className="flex-2">
                                                                        {key || 'N/A'}
                                                                    </p>
                                                                    <p className="flex-1" onClick={() => {
                                                                        console.log(key2)
                                                                    }}>
                                                                        ${Number(value)} {`${index === 0 ? "/hr" : "/day"}`}
                                                                    </p>
                                                                    <p className="flex-1">
                                                                        $ {Number(valuetwo)} /week
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <p>No travel per diems available.</p>
                                                )
                                            }


                                        </div>
                                    </div>

                                </div>


                                {/*<div className={'d-flex shift-details'}>*/}
                                {/*    <div className={'flex-1'}>*/}
                                {/*        <>*/}
                                {/*            <h3>Requested By</h3>*/}
                                {/*            <p>{basicDetails?.requested_by && basicDetails?.requested_by}</p>*/}
                                {/*        </>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Cancel Policy</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.contract_requirements?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Staff Requirements</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.contract_requirements?.staff_requirements?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Job Details</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.contract_requirements?.job_details?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Job Benefits</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.contract_requirements?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                            </>}

                    </div>


                    <div>
                        {basicDetails?.status === "cancelled" ? (
                            <div className="mrg-top-10 custom-border pdd-top-10">
                                <div className="">
                                    <h2>Reason for Cancellation</h2>
                                    <p>{basicDetails?.cancelled_details?.reason}</p>
                                </div>
                                <div className="reject-by-wrapper d-flex">
                                    <div>
                                        <h3>Cancelled By:</h3>
                                        <p>
                                            {basicDetails?.cancelled_details?.user_info?.first_name} &nbsp; {basicDetails?.cancelled_details?.user_info?.last_name}
                                        </p>
                                    </div>
                                    <div className="mrg-left-50">
                                        <h3>Role:</h3>
                                        <p>{basicDetails?.cancelled_details?.user_info?.role}</p>
                                    </div>
                                    <div className="mrg-left-50">
                                        <h3>Cancelled Date:</h3>
                                        <p>{basicDetails?.cancelled_details?.cancelled_date ? moment(basicDetails?.cancelled_details?.cancelled_date).format("MM-DD-YYYY") : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className="header mrg-top-20">
                        <div className="filter"></div>
                        <div className="actions">
                            <Tooltip title={`Add Staff to Shift Requirement`}>
                                <Button variant={"contained"} onClick={openAdd} color={"primary"}
                                        disabled={basicDetails?.status === "cancelled"}>
                                    <AddRounded/>
                                    &nbsp;&nbsp; Add Staff
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="hcp_tabs mrg-top-10 custom-border pdd-10">
                        <div className="tabs_header">
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary"
                                  textColor="primary" variant="fullWidth" scrollButtons="auto">
                                <Tab label="Staff Pending" value={"pending"}/>
                                <Tab label="Staff Approved" value={"approved"}/>
                                <Tab label="Staff Rejected" value={"rejected"}/>

                            </Tabs>
                        </div>
                        <div className="mrg-top-10">
                            {tabValue === "pending" &&
                                <PendingHcpApplicationComponent isAddOpen={isAddOpen} status={basicDetails?.status}/>}
                            {tabValue === "approved" && <ApprovedHcpApplicationComponent isAddOpen={isAddOpen}/>}
                            {tabValue === "rejected" && <UnApprovedHcpApplicationComponent isAddOpen={isAddOpen}/>}

                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default RequirementsShiftsViewScreen;
