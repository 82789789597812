import {Button, Checkbox, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass,
} from "../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import {ENV} from "../../../../constants";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import ShiftFilter from "../../filters/ShiftFilter";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import HcpSentHomeDialogComponent from "../../hcpSentHomeDialog/HcpSentHomeDialogComponent";
import "./ApprovedShiftsListScreen.scss";
import {CssTextField} from "../../../../constants/data/styles";
import HcpNoShowConfirmationBox from "./hcp-no-show-confirm-closed/HcpNoShowConfirmationBox";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";

const ApprovedShiftsListScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta)
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [regions, setRegions] = useState<any>([]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any[]>("selectedRegion", []);
    const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);
    const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    const [selectedSalaryCreditTypes, setSelectedSalaryCreditTypes] = useLocalStorage<any[]>("selectedSalaryCreditTypes", []);
    const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [null, null]);
    const {regions} = useSelector((state: StateParams) => state.meta)
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftApprovePageSizeIndex", 10);

    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isAllselected, setAllSelected] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<any>(-1);

    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isHcpSentHomeOpen, setIsHcpSentHomeOpen] = useState<boolean>(false);
    const [isHcpNoShow, setIsHcpNoShow] = useState<boolean>(false);

    const [shiftSearch, setShiftSearch] = useState<string>("");
    const [isHcpNoShowApiInProgress, setIsHcpNoShowApiInProgress] = useState<boolean>(false)
    const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("approved-shift-requirement-type", "facility");
    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts]
    );
    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }
        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }
        payload.status = ['pending'];


        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);

        }

        if (shiftSearch !== "") {
            payload.search = shiftSearch
        }


        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        }
        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [selectedRegion, dateRange, selectedFacilities, selectedHcps, selectedSalaryCreditTypes, selectedApprovedHcps, selectedTimeTypes, shiftSearch, shiftRequirementType]);

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index === -1) {
                    temp.push(item._id);
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            setSelectedCount(1);
        } else {
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
            });
            setSelectedShifts([...selectedShifts]);
            setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setAllSelected(event.target.checked);
    };

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
                setSelectedCount(1);
            } else {
                let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
                setSelectedShifts([...tempSelectedShifts]);
                if (tempSelectedShifts?.length === 0) {
                    setSelectedCount(-1);
                }
            }
        },
        [selectedShifts]
    );

    // const getHcpTypes = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-types")
    //         .then((resp) => {
    //             setHcpTypes(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    // const getRegions = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-regions")
    //         .then((resp) => {
    //             setRegions(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            const selectedRegions = selectedRegion;
            payload.regions = selectedRegions;
        }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, [selectedRegion]);

    useEffect(() => getFacilityData(), [selectedRegion, getFacilityData]);

    const init = useCallback(() => {
        let url = "shift/approved";
        let payload: any = {};

        payload.status = "pending";

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }


        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: [
                    "Title",
                    "Facility Name",
                    "HCP Name",
                    "Requested On",
                    "Required On",
                    "HCP Type",
                    "Shift Type",
                    "Timesheet",
                    "Facility Confirmation",
                    "Actions",
                ],
                mobileMatColumns: [
                    "Title",
                    "Facility Name",
                    "HCP Name",
                    "Requested On",
                    "Required On",
                    "HCP Type",
                    "Shift Type",
                    "Timesheet",
                    "Facility Confirmation",
                    "Actions",
                ],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [shiftRequirementType]);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }

        let payload: any = {};

        payload.status = "pending";
        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }


        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);

        // eslint-disable-next-line
    }, [selectedRegion, dateRange, selectedFacilities, selectedHcps, selectedTimeTypes, selectedSalaryCreditTypes, selectedApprovedHcps, shiftRequirementType]);

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        list?.table.reload();
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, [list?.table, setSelectedCount, setSelectedShifts]);

    const openHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(true);
    }, []);

    const openHcpNoShow = useCallback(() => {
        setIsHcpNoShow(true)
    }, [])

    const cancelHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const cancelHcpNoShow = useCallback(() => {
        setIsHcpNoShow(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmHcpSentHome = useCallback(() => {
        setIsHcpSentHomeOpen(false);
        list?.table.reload();
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, [list?.table, setSelectedCount, setSelectedShifts]);

    const confirmHcpNoShow = useCallback(() => {
        const payload = {shift_ids: selectedShifts};
        setIsHcpNoShowApiInProgress(true)
        ApiService.patch(ENV.API_URL + "shift/noShow", payload)
            .then((res: any) => {
                CommonService.showToast(res?.msg || "Success", "success");
                setIsHcpNoShowApiInProgress(false)
                setIsHcpNoShow(false);
                list?.table.reload();
                setSelectedCount(-1);
                setSelectedShifts([]);
            })
            .catch((err) => {
                setIsHcpNoShowApiInProgress(false)
                CommonService.showToast(err?.msg || err?.error || "Error", "error");
                setIsHcpNoShow(false);
                setSelectedCount(-1);
                setSelectedShifts([]);
            });
    }, [list?.table, setSelectedCount, setSelectedShifts, selectedShifts]);

    const clearFilterValues = () => {
        setSelectedTimeTypes([]);
        setSelectedSalaryCreditTypes([]);
        setSelectedFacilities([]);
        setSelectedApprovedHcps([]);
        setSelectedHcps([]);
        setDateRange([null, null]);
        setSelectedRegion([]);
        setRequirementType("")
    };

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Shifts Approved");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [list?.table?.data, selectedShifts]);

    return (
        <div className="pending-shifts screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent
                    cancel={cancelRejectShift}
                    confirm={confirmRejectShift}
                    selectedShifts={selectedShifts}
                    isMultipleReasons={true}
                />
            </DialogComponent>

            <DialogComponent open={isHcpSentHomeOpen} cancel={cancelHcpSentHome}>
                <HcpSentHomeDialogComponent
                    cancel={cancelHcpSentHome}
                    confirm={confirmHcpSentHome}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            <DialogComponent open={isHcpNoShow} cancel={cancelHcpNoShow}>
                <HcpNoShowConfirmationBox
                    cancelMarkingPendingConcern={cancelHcpNoShow}
                    confirmMarkingPendingConcern={confirmHcpNoShow}
                    selectedShifts={selectedShifts}
                    isHcpNoShowApiInProgress={isHcpNoShowApiInProgress}
                />
            </DialogComponent>


            {list && list.table?._isDataLoading && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}
            <ShiftFilter
                shiftRequirementType={shiftRequirementType}
                setRequirementType={setRequirementType}
                isFacilityListLoading={isFacilityListLoading}
                dateRange={dateRange}
                setDateRange={setDateRange}
                regions={regions}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedHcps={selectedHcps}
                setSelectedHcps={setSelectedHcps}
                selectedTimeTypes={selectedTimeTypes}
                setSelectedTimeTypes={setSelectedTimeTypes}
                selectedFacilities={selectedFacilities}
                setSelectedFacilities={setSelectedFacilities}
                noStatus={true}
                isApproved={true}
                resetFilters={resetFilters}
                facilityList={facilityList}
                hcpTypes={hcpTypes}
                selectedApprovedHcps={selectedApprovedHcps}
                setSelectedApprovedHcps={setSelectedApprovedHcps}
                selectedSalaryCreditTypes={selectedSalaryCreditTypes}
                setSelectedSalaryCreditTypes={setSelectedSalaryCreditTypes}
            />
            <div className="custom-border pdd-10 pdd-top-0 pdd-bottom-20 mrg-top-0">
                <div className="header">
                    <div className="mrg-left-5 filter">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    {!list?.table.filter.search ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon
                                                onClick={(event) => {
                                                    if (list && list.table) {
                                                        list.table.filter.search = "";
                                                        setShiftSearch("")
                                                        list.table.reload();
                                                        // list?.table.pageEvent(0)
                                                    }
                                                }}
                                                id="clear_shift_search"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <CssTextField
                                            defaultValue={""}
                                            className="search-cursor searchField"
                                            id="input_search_shift"
                                            onChange={(event) => {
                                                if (list && list.table) {
                                                    list.table.filter.search = event.target.value;
                                                    setShiftSearch(event.target.value);
                                                    list.table.reload();
                                                    // list?.table.pageEvent(0)
                                                }
                                            }}
                                            value={list?.table.filter.search}
                                            variant={"outlined"}
                                            size={"small"}
                                            type={"text"}
                                            placeholder={"Search Shift"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions d-flex">
                        <div className="mrg-left-20">
                            <Tooltip title={"Send HCP Home"}>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    disabled={selectedCount === -1}
                                    className={`${selectedShifts.length === 0 ? 'btn-disabled' : ''}`}
                                    onClick={openHcpSentHome}
                                >
                                    &nbsp;&nbsp;HCP Staff Home&nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>

                        <div className="mrg-left-20">
                            <Tooltip title={"Send No Show"}>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    disabled={selectedCount === -1}
                                    className={`${selectedShifts.length === 0 ? 'btn-disabled' : ''}`}
                                    onClick={openHcpNoShow}
                                >
                                    &nbsp;&nbsp;HCP No Show&nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>

                        <div className="mrg-left-20">
                            <Tooltip title={"Cancel Shift Requirement"}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    disabled={selectedCount === -1}
                                    onClick={openRejectShift}
                                >
                                    &nbsp;&nbsp;Cancel Shift&nbsp;&nbsp;
                                </Button>
                            </Tooltip>
                        </div>
                        <div className="mrg-left-20">
                            <Tooltip title="Download Shifts List">
                                <Button variant={"contained"} color="primary" onClick={handleDownload}
                                        className={!isDownloading ? "" : "has-loading-spinner"}
                                        disabled={isDownloading}>
                                    &nbsp;Download
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {list && list.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive"}>
                            <Table stickyHeader className="mat-table table shifts-approved-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        <TableCell padding="checkbox" className="mat-th">
                                            <Checkbox
                                                onChange={(event) => handleSelectAll(event)}
                                                checked={isAllselected}
                                                id={"select-all-cb"}
                                            />
                                        </TableCell>
                                        {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                key={"header-col-" + columnIndex}
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                            >
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {!list.table._isDataLoading && list.table?.data.length === 0 && (
                                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>
                                    )}
                                    {list?.table.data.map((row: any, rowIndex: any) => {
                                        const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                        const isItemSelected = isSelected(row["_id"]);
                                        const isFacilityApproved = row?.hasOwnProperty('is_facility_approved') && row["is_facility_approved"];
                                        const isTimeSheetUploaded = row?.hasOwnProperty('is_timesheet_uploaded') && row["is_timesheet_uploaded"];

                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className="mat-tr">
                                                <TableCell className="mat-td mat-td-checkbox">
                                                    <Checkbox
                                                        id={"cb_" + rowIndex}
                                                        checked={isItemSelected}
                                                        onChange={(event) => handleSelectShifts(event, row["_id"])}
                                                    />
                                                </TableCell>
                                                {/*<TableCell className="mat-td mat-td-title">{row["title"]}</TableCell>*/}
                                                <TableCell
                                                    className="mat-td mat-td-title"
                                                    style={{minWidth: "350px"}}>{shift_date + "_" + row["shift_type"] + "_" + row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name + "_" + row["facility"]?.name}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>
                                                <TableCell className="mat-td mat-td-hcp-name">
                                                    {row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name}
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-created-at">
                                                    {moment(row["created_at"]).format("MM-DD-YYYY")}
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">{shift_date}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-hcp-type">{row["hcp_type"]}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-shift-type">{row["shift_type"]}</TableCell>
                                                <TableCell className="mat-td mat-td-timesheet">{isTimeSheetUploaded ?
                                                    <Checkbox checked={isTimeSheetUploaded}/> :
                                                    <Checkbox checked={Boolean(isTimeSheetUploaded)}/>}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-is-facility-approved">{isFacilityApproved ?
                                                    <Checkbox checked={isFacilityApproved}/> :
                                                    <Checkbox checked={Boolean(isFacilityApproved)}/>}</TableCell>
                                                <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                    <Tooltip title={`${row["title"]} shift view details`}>
                                                        <Link
                                                            to={"/approvedShifts/view/" + row["_id"]}
                                                            className="info-link"
                                                            id={"link_hospital_details" + rowIndex}
                                                        >
                                                            {"View Details"}
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                            component="div"
                            count={list?.table.pagination.totalItems}
                            rowsPerPage={list?.table.pagination.pageSize}
                            page={list?.table.pagination.pageIndex}
                            onPageChange={(event, page) => list.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                list.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ApprovedShiftsListScreen;
